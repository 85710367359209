.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.howToBot_section_heading{
  font-family: Century Gothic;
  font-style: normal;
  font-weight: bold;
  font-size: 70px;
  /* line-height: 148.19%; */
  /* or 150px */
  display: flex;
  justify-content: center;
  text-align: center;
  
  color: #08344D;
  

}
.card_img{
  width:222220px;

}
.howToBot_card{

  filter: drop-shadow(8px 4px 5px rgba(0, 0, 0, 0.25));
  margin-bottom: 30px;
}
.howToBot_card-title{
  /* font-family: "Rounded Mplus 1c Bold"; */
font-style: normal;
font-weight: bold;
font-size: 28px  !important;
/* line-height: 148.19%; */
/* or 77px */

text-align: center;
letter-spacing: -0.075em;

color: #263238;

}
.howToBot_section_tagline{

  font-family: Century Gothic;
font-style: normal;
font-weight: normal;
font-size: 30px;
/* line-height: 148.19%; */
/* or 56px */

text-align: center;
letter-spacing: -0.025em;

color: #08344D;
}
.section_heading{
  font-style: normal; 
  text-align: center; 
  font-weight: bold; 
  font-size: 37px; 
  color: #07344D;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
}

.section_second_heading{
  
  font-style: normal; 
  text-align: center; 
  font-weight: bold; 
  font-size: 24px; 
  color: #07344D;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  padding-bottom:20px;
  padding-top:20px;
}

  

.App-banner h1{
 padding:10px;
 
  font-style: normal;
  font-weight: bold;
  /* font-size: 52px; */

  font-size: 38px;
    /* width: 50%; */
  /* line-height: 144.69%; */
  /* or 93px */
  
  
  color: #07344D;
  
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
}

.app-hero-paragraph {
  /* position: absolute; */
/* width: 371px; */
/* width: 70%; */

/* height: 201px; */
/* left: 51px; */
/* margin-top: 22px; */
padding:10px;
/* font-family: Rounded Mplus 1c Bold; */
font-style: normal;
font-weight: bold;
font-size: 15px;
/* line-height: 123.69%; */
/* or 25px */


color: #313131;

}




.free-sign-up-btn {
  display:flex;
  justify-content: center;
}
.free-sign-up-btn button {

  /* position: absolute; */
width: 251px;
height: 60px;
/* left: 65px;
top: 775px; */

background: #5CC6CC;
border: 2px solid #06344D;
box-sizing: border-box;
color:#fff;
border-radius: 55px;

/* font-family: Rounded Mplus 1c Bold; */
font-style: normal;
font-weight: bold;
font-size: 25px;
/* line-height: 123.69%; */

}



.sale-btn {
  display:flex;
  
  align-items: center;
}
.sale-btn button {

  /* position: absolute; */
margin-top:10px;
width: 159px;
height: 40px;
background: #5CC6CC;
border: 2px solid #06344D;
box-sizing: border-box;
color:#fff;
border-radius: 55px;

/* font-family: Rounded Mplus 1c Bold; */
/* font-style: normal; */
font-weight: bold;
/* font-size: 25px; */
/* line-height: 123.69%; */

}

/* .my-Cont::before{
  content: "";
  background-image: url(/union.png);
background-size: contain;
  
  background-repeat: no-repeat;
  content: "";
  display: block;
  position: absolute;
  top:-10%;
  left:20%;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-position: -10% -30%;
} */
.app-hero-button{
  display:flex;
  justify-content: center;
}


.App-banner p button {

  /* position: absolute; */
width: 281px;
height: 65px;
/* left: 65px;
top: 775px; */

background: #FFFCFC;
border: 5px solid #5BC6CC;
box-sizing: border-box;
color: #5BC6CC;
border-radius: 55px;

/* font-family: Rounded Mplus 1c Bold; */
font-style: normal;
font-weight: bold;
font-size: 25px;
/* line-height: 123.69%; */

}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

.App-Nav-link {
  color: #08344D !important;


  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  /* line-height: 123.69%; */
/* or 28px */

text-align: center;


}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.signup-banner {
  padding: 20px;
  width: 100%;
  font-size: 20px;
  background-color: #45b0b9;
  color: #5ac5cb;
  text-align: center;
  border: 1px solid;
  display: flex;
  justify-content: center;
}

.signup-button {
  color: rgb(90, 197, 203);
  border: 2px solid rgb(90, 197, 203);
  border-radius: 50px;
  padding: 3px;
  font-size: 27px;
  width: 300px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
}

.signin-button {
  color: #2c769e;
}

.signup-button:hover {
  /* background-color:  #56c2c8; */
  color: #51adb2;
  border: 2px solid #51adb2;
}

.signin-button:hover {
  color: #225e7f;
}

.signup-border {
  color: whitesmoke;
  /* padding: 30px; */
  border: 2px solid white;
  /* background-color:  #56c2c8; */
}

.signup-border:hover {
  color: white;
}

.header-inner {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  width: 70%;
  margin: 0 auto;
}

.banner-img {
  background-image: url(./assets/images/banner.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  text-align: center;
  height: 800px;
  width: 100%;

}


.auto-post-img {
  background-image: url(./assets/images/autoPost.gif);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  text-align: center;
  height: 400px;
  width: 100%;

}
.banner-text {
  height: 100%;
  width: 80%;
  margin: 0 auto;
  font-size: 40px;
  padding: 1rem;
  text-align: center;
}
/* .header-top{
  position: fixed;
} */

.typewriter h1 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid orange; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;

  width: fit-content;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

.para {
  font-size: 24px;
  color: black;
  font-weight: 200;
  text-align: justify;
}

.signin-buttons {
  background: #5ac5cb !important;
}
.signin-buttons:hover {
  background: #2cbfc7 !important;
}

.red-text {
  color: red;
}

/**/

.arrow-holder {
  position: relative;
  display: flex;
  align-items: center;
}

.arrow {
  float: left;
  margin-right: 5px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.arrow-right {
  border-left: 15px solid white;
}

.arrow-left {
  border-right: 15px solid white;
}

@keyframes flash {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#arrow1 {
  animation: flash 2.5s infinite;
}

#arrow2 {
  animation: flash 2.5s infinite 0.5s;
}

#arrow3 {
  animation: flash 2.5s infinite 1s;
}

#arrow4 {
  animation: flash 2.5s infinite 1.5s;
}

#arrow5 {
  animation: flash 2.5s infinite 2s;
}

.hoverPointer:hover{
  cursor: pointer;
}